.BannData h1 {
  text-transform: uppercase;
  font-family: "Barlow", sans-serif;
  font-size: 48px;
  font-weight: 700;
  color: #f1d513;
}

.BannData p {
  font-size: 16px;
  color: #d7d7d7;
}

.HomeTabs .nav-tabs button {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  background: #1c1c1d;
  backdrop-filter: blur(25.65199851989746px);
  color: #fff;
  font-family: "Avant semibold";
  font-size: 16px;
  min-width: 184px;
}

.HomeTabs ul {
  border: none;
  display: flex;
  gap: 12px;
}

.HomeTabs .nav-link:hover,
.HomeTabs .nav-link.active {
  color: #000;
  border-radius: 5px;
  background: #f5e003 !important;
  backdrop-filter: blur(25.65199851989746px);
}

.greyCard {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(74, 74, 74, 0.21);
  backdrop-filter: blur(25.65199851989746px);
  padding: 16px;
  color: #fff;
  height: 100%;
}

.tabContent .greyCard h1 {
  font-size: 40px;
  font-weight: 700;
}

.tabContent .greyCard hr {
  background: #ffffff24;
}

.tabContent .greyCard span {
  font-family: "Avant semibold";
  font-size: 14px;
}

.tabContent .greyCard p {
  font-family: "Avant semibold";
}

.tabContent .greyCard svg {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 96% !important;
  }
}

.chartBG {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(1, 1, 1, 0.21);
  box-shadow: 0px 4px 29.9px 0px rgba(255, 255, 255, 0.13) inset;
  backdrop-filter: blur(25.65199851989746px);
  padding: 20px;
}

.yellow {
  color: #f1d513 !important;
}

.ChartTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  border-left: 5px solid #f1d513;
  padding-left: 14px;
  /* padding-bottom: 20px; */
}

.chartTitleData p {
  color: #fff;
}

.greyCard .yellow {
  display: inline-block;
}

.SmallTitle p {
  color: #b4b4b4;
  font-size: 11px;
  margin: 0;
}

.SmallTitle h2 {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.SmallTitle {
  display: flex;
  gap: 20px;
  align-items: center;
}

.insightCard i {
  position: absolute;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
}

.insightCard {
  position: relative;
}

.mainChart img {
  width: 100%;
}

.TabFilters select {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  background: #1c1c1d;
  backdrop-filter: blur(25.65199851989746px);
  color: #fff;
  font-family: "Avant semibold";
  font-size: 16px;
  padding: 10px 30px;
  cursor: pointer;
}

.TabFilters {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: -43px;
}

.p-10-0 {
  padding: 0 10% !important;
}

.p-5-0 {
  padding: 0 5% !important;
}

canvas {
  border-radius: 10px;
}

.kpCard span {
  font-size: 12px;
}

.kpCard h1 {
  font-family: "Avant bold";
  font-size: 38px;
}

.kpCard h1 div {
  font-size: 14px;
}

.kpCard p {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}

.div-block p {
  color: #fff;
}

.beerIndus {
  background: rgba(25, 25, 25, 0.28);
  backdrop-filter: blur(22.910192489624023px);
  border-radius: 10.473px;
  border: 1px solid rgba(22, 156, 232, 0.68);
}

.dis_f_c {
  display: flex;
  align-items: center;
}

.r_90 {
  writing-mode: tb-rl;
  -webkit-transform: rotate(180deg);
  width: 30px;
}

.text-block-2 {
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.font_26 {
  font-size: 26px;
}

.line {
  background: linear-gradient(90deg, #101010 0%, #FAFAFA 51%, #101010 100%);
  height: 2px;
  opacity: 0.3;
  margin: 10px 0px 30px 0px;
}

.loginDivWrapper {
  position: fixed;
  height: 100vh;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 650px;
  padding: 0 20px;
}

.loginDiv {
  position: relative;
  text-align: center;
  border-radius: 10px;
  background: #1d1d1d;
  padding: 40px 20px;
  max-width: 490px;
  width: 100%;
  margin: 0px auto;
  color: #fff;
}

.loginDivWrapper p a:hover{
  color: #fff;
}
.loginDivWrapper p a,
.loginDivWrapper p {
  font-size: 14px;
  color: #c3c3c3;
  text-align: center;
}

.loginDiv h2 {
  font-size: 18px;
  font-family: "Avant semibold";
}

.loginButton img {
  margin-left: 6px;
}

.loginButton:hover {
  background: #fff !important;
}

.loginButton svg {
  margin-left: 6px;
}
.pageFooter hr {
  border: none;
  width: 130px;
  display: block;
  margin: 10px auto;
  height: 2px;
  background: #45453e;
  opacity: 1;
}
.pageFooter p{
  font-size: 10px;
  margin: 0;
}
.pageFooter {
  padding: 16px;
  background: #000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: #fff;
}

.loginButton {
  border-radius: 50px;
  background: #dcb42c;
  border: none;
  -webkit-backdrop-filter: blur(25.65199851989746px);
  backdrop-filter: blur(25.65199851989746px);
  color: #000;
  width: auto;
  padding: 6px 20px;
  height: 40px;
  font-family: "Avant semibold";
}
.topLine {
  border-bottom: 1px solid var(--ABi, #f1d513);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
}
.bootomLine {
  border-radius: 34px;
  background: var(
    --ABi,
    linear-gradient(189deg, #f1d513 -5.31%, #d0a23b 99.07%)
  );
  width: 191px;
  height: 8px;
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
}

.proOff img {
  max-width: 100px;
}

.proOff {
  margin-top: 40px;
}

.f-20 {
  font-size: 20px !important;
}

.MobileTabDropdown select:focus {
  outline: 0;
}

.MobileTabDropdown select {
  cursor: pointer;
  font-family: "Avant semibold";
  border-radius: 2.639px;
  border: 0.528px solid #f5e003;
  background: #f5e003;
  box-shadow: 0px 2.111px 15.781px 0px rgba(255, 255, 255, 0.13) inset;
  backdrop-filter: blur(13.538871765136719px);
  padding: 10px 20px 10px 30px;
  color: #000;
}

.MobileTabDropdown label {
  color: #fff;
  font-size: 16px;
}

.MobileTabDropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
}

.selectWrap {
  position: relative;
}

.selectWrap img {
  position: absolute;
  display: none;
  z-index: 3;
  width: 100%;
  max-width: 15px;
  left: 10px;
  top: 11px;
}

.selectWrap select {
  /* background-color: #1c1c1d; */
  color: #000;
}

.selectWrap select > option {
  background-color: #1c1c1d;
  color: #fff;
}

.selectWrap select option:checked {
  background-color: #1c1c1d;
  color: #fff;
}

.ta-c {
  text-align: center;
}

.volumeChart {
  /* scrollbar-width: thin; */
  /* scrollbar-color: #888 #ccc; */
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(100% + 20px);
}

.subVolumeChart {
  min-width: 1200px;
  width: 100%;
}

.footnote-container {
  text-align: right;
  margin-top: 50px;
}

.footnote {
  font-family: "Arial", sans-serif;
  font-size: 14px;
  color: #b3b3b3;
  /* Light grey for readability */
  background-color: #222222;
  /* Dark background to blend with the chart's theme */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444444;
  /* Subtle border for separation */
  width: fit-content;
  margin: 0 0 0 auto;
  /* Align element to the right within the container */
}

.footnote span {
  color: #ffc107;
  /* Accent yellow for highlighting key text */
}

.countriesTabbs::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px !important;
  height: 5px !important;
}
.countriesTabbs::-webkit-scrollbar-track {
  border-radius: 20px !important;
  background: #1b1b1b !important;
  width: 5px !important;
}

.countriesTabbs::-webkit-scrollbar-thumb {
  background-color: #f1d513 !important;
  border-radius: 20px !important;
}

.yellowDivider hr {
  height: 1px;
  background: #f1d513;
  border: none;
}

ul {
  list-style: none;
}

.InnerTabbsBtns,
.Zones {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 0;
  padding: 0;
}

.Zones li:hover,
.Zones li.active {
  color: #000;
  background: #f5e003;
  backdrop-filter: blur(25.65199851989746px);
  cursor: pointer;
}

.countriesTabbs ul li:hover,
.countriesTabbs ul li.active {
  cursor: pointer;
  color: #f5e003;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #2a2816;
  backdrop-filter: blur(25.65199851989746px);
}

.countriesTabbs ul li:hover i,
.countriesTabbs ul li.active i {
  opacity: 1;
  left: 0;
}

.countriesTabbs ul li i {
  position: relative;
  left: -10px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.countriesTabbs ul li {
  text-align: left !important;
  padding-left: 16px !important;
  justify-content: space-between !important;
}

.countriesTabbs ul li,
.Zones li {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(65, 65, 65, 0.3);
  backdrop-filter: blur(25.65199851989746px);
  flex: 1;
  padding: 5px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  min-height: 42px;
  font-family: "Avant semibold";
  display: flex;
  align-items: center;
  justify-content: center;
}

.countriesTabbs ul {
  padding: 0;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.backBtn a:hover {
  color: #f5e003;
}

.backBtn a {
  color: #ebebeb;
  text-decoration: none;
}

.InnerTabbsBtns li:hover,
.InnerTabbsBtns li.active {
  cursor: pointer;
  background: #1b1b1b;
  border-bottom: 2px solid #d7a833;
  font-family: "Avant semibold";
}

.InnerTabbsBtns li {
  font-size: 14px;
  flex: 1;
  background: transparent;
  border-bottom: 2px solid transparent;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.header2 {
  padding: 16px 0;
  position: sticky;
  top: 0px;
  background: #000;
  z-index: 1;
  border-bottom: 2px solid #d7a833;
  margin-bottom: 20px;
}

.InnerTabbs {
  background: #000;
}

/* .InnerTabbs,
.countriesTabbs {
  position: sticky;
  top: 96px;
  z-index: 1;
} */

.chartWrapper {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(1, 1, 1, 0.21);
  box-shadow: 0px 4px 29.9px 0px rgba(255, 255, 255, 0.13) inset;
  backdrop-filter: blur(25.65199851989746px);
  padding: 16px;
}

.bgBlack {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000 100%);
}

.chartWrapper p {
  color: #c2c2c2;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.positive {
  color: #02c712 !important;
}
.negative {
  color: #cc0303 !important;
}

.switch {
  position: relative;
  display: inline-block;
  min-width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 2px solid #fff;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 2px;
  background-color: #f1d513;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #000;
  border: 2px solid #fff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.topToggle p {
  margin: 0;
  color: #fff;
  opacity: 0.6;
  font-size: 14px;
}

.topToggle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title-text {
  color: #a8a8a8;
  font-size: 20px;
}

.infoIcon {
  width: 16px;
  height: 16px;
}

.Gbox {
  text-align: center;
  position: relative;
}

.Gbox:before {
  content: "";
  background: #414141;
  width: calc(100% - 135px);
  height: 1px;
  position: absolute;
  left: 115px;
  top: 20px;
}

.Gbox p {
  position: relative;
  display: inline-block;
  border-radius: 2.759px;
  background: #414141;
  padding: 6px 9px;
  margin: 0;
  font-size: 13px;
}

span.cgr {
  color: #fff;
  opacity: 0.6;
  font-size: 12px;
  position: relative;
  left: 23%;
  bottom: -17px;
}

.cgr.population {
  bottom: 0;
  left: none;
  opacity: 1;
}

.dtBottom span {
  height: 2px;
  width: 14px;
  display: inline-block;
  border-radius: 10px;
}

.dtBottom p {
  display: flex;
  align-items: center;
  font-family: "Avant regular";
  gap: 8px;
  font-size: 12px;
}

.dtBottom {
  display: flex;
  align-items: center;
  gap: 20px;
}

.lBlue {
  background: #0e7fbe;
}

.lRed {
  background: #c86d67;
}
.lwhite {
  background: #fff;
}
.lYellow {
  background: #f5e003;
}
.lDotedYellow {
  background: none;
  border-radius: 0;
  width: 20px !important;
  border-bottom: 2px dotted #f5e003;
}
.lDotedGrey {
  background: none;
  border-radius: 0;
  width: 20px !important;
  border-bottom: 2px dotted #fff;
  opacity: 0.5;
}
.hightlighRow td:first-child {
  border-left: 1px solid #755912 !important;
}
.hightlighRow td:last-child {
  border-right: 1px solid #755912 !important;
}
.hightlighRow td {
  border-radius: 5px;
  border-top: 1px solid #755912 !important;
  border-bottom: 1px solid #755912 !important;
  background: #171205;
  border-radius: 0;
}
.style1Tble table tr th:first-child,
.style1Tble table tr td:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.style1Tble table tr th:last-child,
.style1Tble table tr td:last-child {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.style1Tble table tr th {
  border: none !important;
  background: none !important;
  padding: 0 8px !important;
}
.style1Tble table tr td p {
  font-size: 14px;
  white-space: nowrap;
}
.style1Tble table tr td {
  height: 65px;
}
.style1Tble table tr th,
.style1Tble table tr td {
  white-space: nowrap;
  color: #b3b3b3;
  text-align: center;
  font-size: 14px;
  font-family: "Avant semibold";
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(38, 38, 38, 0.34);
  padding: 10px;
}
.style1Tble table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;
}
.greyHighlight {
  border-radius: 5px;
  border: 1px solid #3b3932;
  background: #211e18;
  /* box-shadow: 0px 4px 29.9px 0px rgba(255, 255, 255, 0.13) inset; */
  backdrop-filter: blur(25.65199851989746px);
  text-align: center;
  padding: 10px;
}

.colls {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.col-top p {
  flex: 1;
  text-align: left;
  font-family: "Avant regular";
  margin-bottom: 5px;
  font-size: 12px !important;
}
.vBottom td:first-child {
  vertical-align: middle;
}
.vBottom td {
  vertical-align: bottom;
}
.countriesTabbs {
  max-height: 500px;
  overflow: auto;
  position: sticky;
  top: 108px;
}
.tableStyle2 table tr th,
.tableStyle2 table tr td {
  border: 1px solid #424242;
  padding: 10px;
  text-align: center;
  color: #c2c2c2;
}
.tableStyle2 table {
  width: 100%;
  border-collapse: collapse;
}

.InnerTabContent .kpCard hr {
  background: rgba(255, 255, 255, 0.14);
  border: none;
  height: 1px;
  margin: 0;
}

.mt-10 {
  margin-top: 10px !important;
}
.tableStyle3 table {
  width: 100%;
  border-collapse: collapse;
}
.tableStyle3 tr td:first-child {
  text-align: left;
}
.tableStyle3 tr td:first-child,
.tableStyle3 tr th {
  color: #767676 !important;
}
.tableStyle3 tr th,
.tableStyle3 tr td {
  font-size: 14px;
  font-family: "Avant semibold";
  padding: 10px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #010101;
  text-align: center;
}
.tableStyle3 tr th {
  background: #161616;
}

.parent_btn {
  height: 2px;
  width: 14px;
  display: inline-block;
  border-radius: 10px;
}



/* ========================== */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}