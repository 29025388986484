.loader.--7::before,
.loader.--7::after {
  width: var(--size-square);
  height: var(--size-square);
  background-color: var(--color);
}
.loader.--7::after {
  background-color: var(--color1);
}

.loader.--7::before {
  top: calc(50% - var(--size-square));
  left: calc(50% - var(--size-square));
  animation: loader-6 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

.loader.--7::after {
  top: 50%;
  left: 50%;
  animation: loader-7 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes loader-7 {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-100%) translateY(-100%);
  }

  75% {
    transform: translateY(-100%);
  }
}

@keyframes loader-6 {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(100%) translateY(100%);
  }

  75% {
    transform: translateY(100%);
  }
}

.loader {
  --color: #fff;
  --color1: #e5b611;
  --size-mid: 6vmin;
  --size-dot: 1.5vmin;
  --size-bar: 0.4vmin;
  --size-square: 3vmin;

  display: block;
  position: relative;
  width: 50%;
  display: grid;
  place-items: center;
}

.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
}

.loader {
  --color: #fff;
  --color1: #e5b611;
  --size-mid: 6vmin;
  --size-dot: 1.5vmin;
  --size-bar: 0.4vmin;
  --size-square: 3vmin;

  display: block;
  position: relative;
  width: 50%;
  display: grid;
  place-items: center;
}

.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.hidden {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

@keyframes hideDiv {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.hide-after-2s {
  animation: hideDiv 3s forwards;

  display: none;
}

.componentLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* background-color: rgb(55 54 54 / 50%); */
  height: 100%;
  width: inherit;
  z-index: 9999999;
  opacity: 1;
}

.blur{
  filter: blur(6px);
}
